<template>
  <div class="app-container calendar-list-container">
    <div class="tl p10">
      <el-input
        style="width: 300px; margin-right: 30px"
        class="filter-item"
        placeholder="请输入企业名称/社会信用代码"
        v-model="listQuery.keyword"
      >
      </el-input>
      <el-date-picker
        style="margin-right: 30px"
        v-model="timePickerVal"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-input placeholder="请输入内容" readonly v-model="count" type="number" style="width: 260px; margin-right: 50px">
        <template slot="prepend">投诉条数</template>
        <template slot="append">条</template>
      </el-input>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="getList()"
        >搜索</el-button
      >
    </div>

    <el-table
      :key="tableKey"
      ref="table"
      v-loading="listLoading"
      :data="list"
      :height="tableHeight"
      element-loading-text="加载中..."
      border
      fit
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column type="index" align="center" label="序号" width="100" />
      <el-table-column min-width="100" align="center" label="企业名称">
        <template slot-scope="scope">
          <span>{{ scope.row.companyName }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="100" align="center" label="社会信用代码">
        <template slot-scope="scope">
          <span>{{ scope.row.creditCode }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="100" align="center" label="管理员名字">
        <template slot-scope="scope">
          <span>{{ scope.row.adminName }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="100" align="center" label="手机号">
        <template slot-scope="scope">
          <span>{{ scope.row.adminPhone }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="100" align="center" label="投诉条数">
        <template slot-scope="scope">
          <span style="color: #d71345; font-size: 16px; font-weight: bold">{{
            scope.row.count
          }}</span>
        </template>
      </el-table-column>

      <el-table-column
        fixed="right"
        align="center"
        :label="'操作'"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button type="primary" @click="getDetail(scope.row.companyId)"
            >投诉详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination
        background
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog :visible.sync="showDetail" width="80%">
      <div class="tl p10">
        <el-input
          style="width: 500px; margin-right: 50px"
          class="filter-item"
          placeholder="请输入姓名/手机号"
          v-model="detailQuery.keyword"
        >
        </el-input>
        <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-search"
          @click="getDetail(detailQuery.companyId)"
          >搜索</el-button
        >
      </div>
      <el-table
        :key="tableKey"
        ref="table"
        :data="detailList"
        element-loading-text="加载中..."
        border
        fit
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column type="index" align="center" label="序号" width="100" />
        <el-table-column min-width="100" align="center" label="姓名">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="100" align="center" label="手机号">
          <template slot-scope="scope">
            <span>{{ scope.row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="200" align="center" label="不适情况">
          <template slot-scope="scope">
            <span>{{ scope.row.tasteType }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="200" align="center" label="备注">
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column>
        <el-table-column width="80" align="center" label="图片">
          <template slot-scope="scope">
            <el-button
              v-if="JSON.parse(scope.row.imgList).length > 0"
              icon="el-icon-picture"
              type="primary"
              size="mini"
              circle
              @click="showImg(scope.row)"
            />
          </template>
        </el-table-column>
      </el-table>
      <div class="p15">
        <el-pagination
          background
          :current-page="detailQuery.page"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="detailQuery.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="detailTotal"
          @size-change="handleDetailSizeChange"
          @current-change="handleDetailCurrentChange"
        />
      </div>
    </el-dialog>
    <el-dialog
      title="查看图片"
      :visible.sync="imgVisible"
      :width="imgDialogWidth"
    >
      <el-image
        v-for="(img, imgIndex) in imgList"
        :key="imgIndex"
        style="width: 300px; height: 300px"
        :src="img"
        fit="cover"
        :preview-src-list="imgList"
        class="mr20 t-border mb10"
      ></el-image>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../../util/extra-api";
import { local } from "../../../util/util";
import dayjs from "dayjs";

export default {
  name: "SafetyRisk",
  data() {
    return {
      tableKey: 0, // 表格的键
      list: null, // 表格的数据
      total: null, // 表格的数据数量
      listLoading: true, // 表格加载状态
      tableHeight: 450,
      listQuery: {
        keyword: "",
        page: 1, // 表格的页面值
        limit: 20, // 表格的单页数量
      },
      replyData: {
        fb_id: null,
        reply_detail: "",
      },
      imgList: [],
      imgDialogWidth: null,
      replyDialogVisible: false,
      imgVisible: false,
      userDialogVisible: false,
      dialogVisible: false,
      userData: "",
      stUserData: null,
      selectedUser: [],
      multipleSelection: [],
      userNum: 0,
      showSend: false,
      rules: {
        title: [{ required: true, message: "必填项", trigger: "blur" }],
        content: [{ required: true, message: "必填项", trigger: "blur" }],
      },
      baseUrl: process.env.VUE_APP_BASE_API,
      showDetail: false,
      detailList: [],
      detailTotal: null,
      detailQuery: {
        companyId: "",
        keyword: "",
        limit: 10,
        page: 1,
      },
      timePickerVal: [],
      count:4
    };
  },
  created() {
    //this.getList();
  },
  watch: {
    showDetail(val) {
      if (!val) {
        this.detailQuery.keyword = "";
      }
    },
  },
  mounted() {
    //this.listQuery.companyId = local.get('company_id')
    this.getList();
  },
  methods: {
    // toggleSelection() {
    //   this.selectedUser.forEach(u => {
    //     this.$refs.multipleTable.toggleRowSelection(u);
    //   });
    // },
    getDetail(companyId) {
      this.detailQuery.companyId = companyId;
      api
        .get("/v1/pc/evaluation/complaintDetailList", this.detailQuery)
        .then((response) => {
          const results = response.data;
          this.detailList = results.map((m) => {
            return {
              ...m,
              tasteType: m.tasteType.replace("&", ","),
            };
          });
          this.detailTotal = response.total;
          this.showDetail = true;
        });
    },
    getList() {
      this.listLoading = true;
      if(this.timePickerVal===null){
        this.timePickerVal=[]
      }
      let startTime = this.timePickerVal[0]
        ? dayjs(this.timePickerVal[0]).format("YYYY-MM-DD HH:mm:ss")
        : "";
      let endTime = this.timePickerVal[1]
        ? dayjs(this.timePickerVal[1]).format("YYYY-MM-DD HH:mm:ss")
        : "";
      api
        .get("/v1/pc/evaluation/complaintList", {
          ...this.listQuery,
          startTime,
          endTime,
        })
        .then((response) => {
          this.tableHeight =
            window.innerHeight - this.$refs.table.$el.offsetTop - 85;
          const results = response.data;
          this.list = results;
          this.total = response.total;
          this.listLoading = false;
        });
    },
    switchChange(val) {
      api.put("/v1/pc/evaluation/audit/" + val.id, "").then((response) => {
        this.getList();
      });
    },

    showImg(row) {
      if (row.imgList.length == 1) {
        this.imgDialogWidth = "25%";
      } else if (row.imgList.length == 2) {
        this.imgDialogWidth = "30%";
      } else {
        this.imgDialogWidth = "40%";
      }
      this.imgList = JSON.parse(row.imgList);
      this.imgVisible = true;
    },
    // 表格单页数据数量的切换
    handleDetailSizeChange(val) {
      this.detailQuery.limit = val;
      this.getDetail(this.detailQuery.companyId);
    },
    // 表格页数的切换
    handleDetailCurrentChange(val) {
      this.detailQuery.page = val;
      this.getDetail(this.detailQuery.companyId);
    },
    handleSizeChange(val) {
      this.listQuery.count = val;
      this.getList();
    },
    // 表格页数的切换
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.selectedUser = this.multipleSelection;
      this.userNum = this.selectedUser.length;
    },
  },
};
</script>

<style  rel="stylesheet/scss" lang="scss">
.task-form {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}
.filter-item-right {
  display: block;
  float: right;
  margin-bottom: 10px;
}
</style>
